@import 'eolng-variables';

.mat-mdc-snack-bar-container.mat-snack-bar-top {
  margin-top: 50px;
}

.mat-mdc-snack-bar-actions button {
  color: white !important;
  font-weight: bold;
}

.eolng-snackbar--success {
  --mdc-snackbar-container-color: $eolng-green !important;
  background: $eolng-green !important;
  border-radius: 5px;
}

.eolng-snackbar--info {
  --mdc-snackbar-container-color: #0e43b5 !important;
  background: #0e43b5 !important;
  border-radius: 5px;
}

.eolng-snackbar--warning {
  --mdc-snackbar-container-color: $eolng-orange !important;
  background: $eolng-orange !important;
  border-radius: 5px;
}

.eolng-snackbar--danger {
  --mdc-snackbar-container-color: $eolng-red !important;
  background: $eolng-red !important;
  border-radius: 5px;
}
