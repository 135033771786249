@import 'bootstrap-variables';

.wsc-theme-custom {
  .wsc-header {
    background-color: white !important;
    border-bottom: solid 2px #cbcbcb !important;
    line-height: 48px !important;
    height: 48px !important;
    &__icon {
      &,
      &:hover {
        color: black !important;
      }
    }

    &__button {
      &:focus {
        background: none !important;
      }
      &:active {
        .wsc-header__icon {
          color: black !important;
        }
      }
    }
  }

  .wsc-settings__nav {
    background-color: $body-bg !important;
    border-right: 1px solid $default-brand !important;
  }

  .wsc-tabs__item--active {
    color: $default-brand !important;
    border-left: $default-brand !important;
  }

  .wsc-button--primary:not(.wsc-button--disabled) {
    background-color: $default-brand !important;
    color: white !important;
  }

  .wsc-list.wsc-tabs__nav.wsc-settings__nav.wsc-tabs__list {
    display: none !important;
  }

  .wsc-dictionaries__input input:focus {
    border-color: $default-brand !important;
  }

  .wsc-button,
  .wsc-action-items__more-actions .wsc-action-items__list .wsc-button {
    border-color: $default-brand !important;
    color: $default-brand !important;
    &:hover {
      border-color: $default-brand !important;
      background: $default-brand !important;
      color: white !important;
      .wsc-icon:not(.wsc-icon-suggestion) {
        color: white !important;
      }
    }
  }

  .wsc-word-item:hover {
    background: lighten($color: $default-brand, $amount: 60%) !important;
  }

  .wsc-settings .wsc-settings__button:after {
    color: white !important;
  }
}
