/*
* Bootstrap overrides https://getbootstrap.com/docs/4.0/getting-started/theming/
* All values defined in bootstrap source
* https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss can be overwritten here
* Make sure not to add !default to values here
*/
:root {
  --bs-body-color: #dee2e6;
}
$btn-link-color: #dee2e6;
// Colors:
// Grayscale and brand colors for use across Bootstrap.

$primary: #3e8acc;
$info: #17a2b8;
$warning: #ffc107;

// Options:
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;

// Components:
// Define common padding and border radius sizes and more.

$border-radius: 0.15rem;
$border-radius-lg: 0.125rem;
$border-radius-sm: 0.1rem;
$separator-border: 1px solid #c4c4c4;

// warning : if $border-color value change, change value in paged.css file too (scroll bar style)
$border-color: #e0e0e0;
$hover-color: #d0e9ff;

// Body:
// Settings for the `<body>` element.

$body-bg: #eff2f9;
$grey-light: #f0f0f0;
$grey: #a5a5a5;
$dark-grey: #656565;
$section-gray: #f0f0f085;

// Typography:
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 1rem;

$dropdown-link-hover-color: white;
$dropdown-link-hover-bg: #343a40;

$font-family-sans-serif: 'EOLNG_default_police1_HelveticaNeue', arial, helvetica, sans-serif;
$font-family-base: $font-family-sans-serif !default;
$font-family-gotham-rounded: 'EOLNG_default_police2_GothamRounded', sans-serif;
//
// EOLNG Variables
//
// warning : if $default-brand value change, change value in paged.css file too (scroll bar style)
$default-brand: #0c4272;
$html-editor-brand: #00a4e4;
$data-editor-brand: #6dc4c4;
$style-editor-brand: #0361ff;
$ged-brand: #193e8e;
$preview-brand: #195f8e;
$cockpit-brand: #8d195e;
$admin-editor-brand: #0c4272;
$transparentizer: 0.88;
$color-black: #000000;

$header-height: 36px;
$opacity-hover: 0.15;

$default-brand-hover: rgba($default-brand, $opacity-hover);
$html-editor-brand-hover: rgba($html-editor-brand, $opacity-hover);
$data-editor-brand-hover: rgba($data-editor-brand, $opacity-hover);
$style-editor-brand-hover: rgba($style-editor-brand, $opacity-hover);
$ged-brand-hover: rgba($ged-brand, $opacity-hover);
$preview-brand-hover: rgba($preview-brand, $opacity-hover);
$cockpit-brand-hover: rgba($cockpit-brand, $opacity-hover);

$accent: #444444;
$success: #43c596;
$danger: #f55b5b;
$error: #ff0000;

$button-border-radius: 2px;
$toolbar-height: 39px;

// breakpoints
$grid-breakpoints: (
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 1024px,
  // Extra large screen / wide desktop
  xl: 1280px,
);

$ver: unique_id();

$panel-width: 350px;
$panel-header-height: 55px;
$panel-header-font-size: 16px;
$panel-header-color: #707070;
$panel-header-background-color: white;

//
// VERSION TRACKER Variables
//
$sticker-color: #ffc107;
$sticker-hover-color: #ff832b;
$sticker-highlight-color: #2cafffc4;
$diff-insert-container-color: #cbd9ff;
$diff-delete-container-color: #ffb0b0;
$diff-insert-suggestion-color: #007001;
$diff-delete-suggestion-color: #fd0000;

// ANNOTATION : comments and suggestion
$annotation-panel-selected-color: #f3f7fb;
