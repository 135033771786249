//
// GothamRounded
//
@font-face {
  font-family: 'EOLNG_default_police2_GothamRounded';
  font-weight: 300;
  src: url('/content/fonts/gotham-rounded/GothamRoundedLight_21020.ttf') format('truetype');
}

@font-face {
  font-family: 'EOLNG_default_police2_GothamRounded';
  font-weight: normal;
  src: url('/content/fonts/gotham-rounded/GothamRoundedBook_21018.ttf') format('truetype');
}

@font-face {
  font-family: 'EOLNG_default_police2_GothamRounded';
  font-weight: bold;
  src: url('/content/fonts/gotham-rounded/GothamRoundedMedium_21022.ttf') format('truetype');
}

@font-face {
  font-family: 'EOLNG_default_police2_GothamRounded';
  font-weight: 600;
  src: url('/content/fonts/gotham-rounded/GothamRoundedBold_21016.ttf') format('truetype');
}

//
// Helvetica Neue LT
//
@font-face {
  font-family: 'EOLNG_default_police1_HelveticaNeue';
  font-weight: normal;
  src: url('/content/fonts/Helvetica-Neue-LT/HelveticaNeueLTStd-Roman.ttf') format('woff'),
    url('/content/fonts/Helvetica-Neue-LT/HelveticaNeueLTStd-Roman.woff2') format('woff2'),
    url('/content/fonts/Helvetica-Neue-LT/HelveticaNeueLTStd-Roman.woff') format('woff');
}
@font-face {
  font-family: 'EOLNG_default_police1_HelveticaNeue';
  font-weight: bold;
  src: url('/content/fonts/Helvetica-Neue-LT/HelveticaNeueLTStd-Bd.ttf') format('truetype'),
    url('/content/fonts/Helvetica-Neue-LT/HelveticaNeueLTStd-Bd.woff2') format('woff2'),
    url('/content/fonts/Helvetica-Neue-LT/HelveticaNeueLTStd-Bd.woff') format('woff');
}
@font-face {
  font-family: 'EOLNG_default_police1_HelveticaNeue';
  font-weight: 600;
  src: url('/content/fonts/Helvetica-Neue-LT/HelveticaNeueLTStd-Hv.ttf') format('truetype'),
    url('/content/fonts/Helvetica-Neue-LT/HelveticaNeueLTStd-Hv.woff2') format('woff2'),
    url('/content/fonts/Helvetica-Neue-LT/HelveticaNeueLTStd-Hv.woff') format('woff');
}
