@import 'bootstrap-variables';

$splitbar-icon-color: $default-brand;
$resize-icon-bg-color: transparent;
$btn-primary-color: $default-brand;
$btn-primary-bgcolor: $default-brand;
$cbox-checkmark-bgcolor: $default-brand;
$cbox-checkmark-hover-bgcolor: $default-brand;
$cbox-focussed-checkmark-bgcolor: $default-brand;
$cbox-checked-ripple-bgcolo: rgba($default-brand, 0.26);
$tooltip-content-bg-color: $default-brand;
$tooltip-content-font-color: white;
$tooltip-content-border-color: $default-brand;
$tooltip-border-radius: 5px;
$tooltip-drop-shadow: 0 0 4px 0 rgba(38, 7, 26, 0.29), 0 2px 4px 0 rgba(38, 7, 26, 0.29);
$tooltip-font-size: 12px;
$tooltip-horizontal-padding: 8px;
$tooltip-vertical-padding: 8px;

$spreadsheet-border-color: $border-color;
$cell-color: #606060;
$sheet-tab-active-bg-color: $default-brand;
$sheet-tab-hover-bg-color: $default-brand-hover;
$selection-bg-color: $default-brand-hover;
$selection-border-color: $default-brand;
$hdr-selection-bg-color: $default-brand-hover;
$hdr-selection-color: $default-brand;
$sheet-tab-bg-color: white;
$select-icon-color: $default-brand;
$sheet-tab-btn-icon-color: $default-brand;
$tab-text-color: $accent;
$sort-error-font-color: $default-brand;
$header-cell-color: $color-black;
$light-blue-grey: #d7dae0;

@import 'ej2-base/styles/material.scss';
@import 'ej2-buttons/styles/material.scss';
@import 'ej2-calendars/styles/material.scss';
@import 'ej2-inputs/styles/material.scss';
@import 'ej2-splitbuttons/styles/material.scss';
@import 'ej2-lists/styles/material.scss';
@import 'ej2-navigations/styles/material.scss';
@import 'ej2-popups/styles/material.scss';
@import 'ej2-dropdowns/styles/material.scss';
@import 'ej2-spreadsheet/styles/material.scss';

.e-spreadsheet,
.e-wrap-content {
  position: relative;
  .e-sheet-tab-panel {
    .e-tab-header {
      .e-toolbar-item.e-active .e-tab-wrap {
        .e-tab-text,
        .e-icons {
          color: white;
        }
      }

      .e-indicator {
        background: $default-brand;
      }

      .e-toolbar-items .e-toolbar-item {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }

  .e-spinner-pane {
    display: none;
  }

  .e-row:nth-child(odd) .e-cell {
    background-color: $eolng-lighter-grey;
  }

  .e-rowhdr-table,
  .e-row-header,
  .e-sheet-panel:not(.e-rtl) .e-select-all-cell {
    // Do not set "width: 45px !important" to keep 1st column right aligned with axis
    overflow: hidden;
  }

  .e-table tr .e-cell,
  .e-main-panel .e-spreadsheet-edit {
    vertical-align: middle;
    font-size: 14px;
    font-family: $font-family-base;
    padding: 0 6px;
    color: $color-black;
  }

  .e-column-header,
  .e-row-header {
    .e-table .e-header-cell {
      vertical-align: middle;
      font-size: 11px !important ; // Higher font size in axis makes empty line not aligned
      font-family: $font-family-base;
    }
  }

  .e-add-sheet-tab {
    display: none;
  }
}

.e-contextmenu-wrapper ul .e-menu-item .e-menu-icon {
  color: $default-brand !important;
}

.e-tooltip-wrap {
  &.e-popup {
    box-shadow: 0 0 4px 0 rgba(38, 7, 26, 0.29), 0 2px 4px 0 rgba(38, 7, 26, 0.29);
  }
}

.e-richtexteditor .e-toolbar-wrapper {
  display: inline-table;
}

.e-schedule-toolbar-container {
  .e-toolbar-right {
    .e-toolbar-item {
      .e-tbar-btn.e-btn {
        border-radius: 15px;
        border: solid 2px $light-blue-grey;
        padding: 0 10px !important;

        .e-tbar-btn-text {
          color: $light-blue-grey;
        }

        &:active,
        &:hover,
        &:focus {
          background-color: transparent !important;
        }
      }

      &.e-active-view {
        .e-tbar-btn.e-btn {
          border-color: $default-brand;

          .e-tbar-btn-text {
            color: $default-brand;
          }
        }
      }
    }
  }
}

.e-dlg-container {
  z-index: 2001 !important;

  .e-dlg-overlay {
    position: fixed !important;
    background-color: rgba(38, 7, 26, 0.78) !important;
  }
}
