@import 'bootstrap-variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import 'fonts';

@import 'snackbar';

@import './syncfusion';
@import './wsc';
@import 'eolng-variables';

/* ==============================================================
Bootstrap tweaks
===============================================================*/

html,
body {
  height: 100%;
  min-width: 1024px;
  --mdc-typography-body1-font-size: 14px;
}

/* be able to log-out even if loader is on */
.cdk-overlay-container {
  z-index: 100000;
}

/* override hover color for dropdown-item forced by bootstrap to all a:not([href]):not([tabindex]) elements in _reboot.scss */
a:not([href]):not([tabindex]):hover.dropdown-item {
  color: $dropdown-link-hover-color;
}

/* override .dropdown-item.active background-color on hover */
.dropdown-item.active:hover {
  background-color: mix($dropdown-link-hover-bg, $dropdown-link-active-bg, 50%);
}

a:hover {
  /* make sure browsers use the pointer cursor for anchors, even with no href */
  cursor: pointer;
}

.dropdown-item:hover {
  color: $dropdown-link-hover-color;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: $color-black;
  padding: 0.2em 0;
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */
// .ng-valid[required],
// .ng-valid.required {
//   border-left: 5px solid green;
// }

// .ng-invalid:not(form) {
//   border-left: 5px solid red;
// }

/* other generic styles */

.jh-card {
  padding: 1.5%;
  margin-top: 20px;
  border: none;
}

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.preserve-newline {
  white-space: pre-wrap;
}

.readonly {
  background-color: $eolng-light-grey;
  opacity: 1;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  text-align: center;
}

.hand,
[jhisortby] {
  cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;

    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }

    .popover pre {
      font-size: 10px;
    }
  }

  .jhi-toast {
    position: fixed;
    width: 100%;

    &.left {
      left: 5px;
    }

    &.right {
      right: 5px;
    }

    &.top {
      top: 55px;
    }

    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .jhi-toast {
    width: 50%;
  }
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details > {
  dd {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .row.jh-entity-details > {
    dt {
      margin-bottom: 15px;
    }

    dd {
      border-bottom: 1px solid $eolng-light-grey;
      padding-left: 180px;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.mat-drawer {
  overflow-y: hidden;
}

.mat-drawer-inner-container {
  overflow: hidden !important;
}

.mat-drawer-side {
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2) !important;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

mat-tooltip-component .mat-tooltip.sheet-name-tooltip {
  max-width: unset !important;
}

/* jhipster-needle-scss-add-main JHipster will add new css style */

.toolbar-button,
.dimension_button_data_tagger {
  width: 24px !important;
  height: 24px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  margin-right: 12px !important;

  & > *[role='img'] {
    width: 18px;
    height: 16px;
    font-size: 16px;

    svg {
      width: 18px;
      height: 16px;
    }
  }

  .mat-mdc-button-touch-target {
    width: 24px !important;
    height: 24px !important;
  }
}

.small-icon-button {
  border-radius: 3px !important;
  width: 40px !important;
  height: 40px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  line-height: 40px !important;

  & > *[role='img'] {
    width: 15px;
    height: 15px;
    font-size: 15px;

    svg {
      width: 15px;
      height: 15px;
    }
  }

  .mat-mdc-button-touch-target {
    width: 40px !important;
    height: 40px !important;
  }
}

.small-icon-button-and-label {
  .mat-icon {
    line-height: 15px;
  }
  & > *[role='img'] {
    width: 15px;
    height: 15px;
    font-size: 15px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.success {
  color: $success;

  fa-icon {
    margin-right: 10px;
  }
}

.danger {
  color: $danger;

  fa-icon {
    margin-right: 10px;
  }
}

.warning {
  color: $warning;

  fa-icon {
    margin-right: 10px;
  }
}

//Scrollbar for webkit scrollbars, usage is automatic
.data-editor,
.html-editor,
.style-editor,
.admin-editor,
.preview,
.files {
  ::-webkit-scrollbar {
    width: 12px;
    border-radius: 8px;
    height: 15px;
    background-color: var(--white);
  }

  ::-webkit-scrollbar-track {
    border: solid 0 $border-color;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-left: solid 0 $border-color;
    border-right: solid 0 $border-color;
    /* border-radius: 4px; */
    border-top-left-radius: 8px 6px;
    border-top-right-radius: 8px 6px;
    border-bottom-right-radius: 8px 6px;
    border-bottom-left-radius: 8px 6px;
    background-color: $default-brand;
  }

  //Scrollbar for firefox, the class need to be added to overflow: scroll (or auto) containers
  .scrollbar {
    scrollbar-width: thin;
    scrollbar-color: $default-brand var(--white);
  }

  // Colors for mat-select
  .mat-select-panel {
    mat-option {
      &.mat-selected {
        background-color: $default-brand !important;
        color: white !important;

        & > * {
          color: white !important;
        }
      }

      &:hover:not(.mat-selected) {
        background-color: $default-brand-hover !important;
      }
    }
  }
}

.html-editor,
.preview {
  [data-model-type='summary'] {
    .summary-item {
      width: fit-content;
      cursor: not-allowed;

      &.clickable {
        cursor: pointer;
      }
    }
  }
}

button {
  outline: none !important;
}

/* ==========================================================================
Header panel
========================================================================== */
.panel-block-left,
.panel-block-right {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .panel-header-block {
    position: sticky;
    top: 0px;
    display: flex;
    flex: 0 0 auto;
    height: $panel-header-height;
    align-items: center;
    justify-content: space-between;
    border-bottom: $separator-border;
    background-color: $panel-header-background-color;
    font-size: $panel-header-font-size;
    z-index: 5;

    .header-title {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      color: $panel-header-color;
    }
  }

  .panel-content-block {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
  }
}

.panel-block-left {
  width: $panel-width;

  .panel-header-block {
    padding-left: 15px;
  }
}

.panel-block-right {
  padding: 0;
  font-size: 12px;

  .mat-mdc-form-field-infix {
    width: inherit;
  }

  ::placeholder {
    font-size: 12px;
  }
}

.header-close {
  width: 25px !important;
  height: 25px !important;
  line-height: 23px !important;

  .mat-button-focus-overlay {
    display: none;
  }

  .mat-icon {
    line-height: 23px !important;
  }
}

mat-accordion.mat-accordion.standard {
  mat-expansion-panel {
    border-radius: 0 !important;
    margin-bottom: 15px !important;

    &.mat-expanded {
      box-shadow: none;
    }
  }

  mat-expansion-panel-header {
    height: 50px;

    &[aria-expanded='true'] {
      margin-bottom: 15px;
      background-color: $grey-light;
      box-shadow: none;
    }
  }

  mat-panel-title {
    color: $default-brand;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin-right: 0;

    button,
    mat-icon {
      height: 24px;
      width: 24px;
      line-height: 20px;
    }
  }

  ::ng-deep {
    .mat-expansion-panel-body {
      padding: 0;
    }
  }
}

.ck-annotation-wrapper--active {
  .ck-annotation {
    background: $annotation-panel-selected-color;
  }
}

.data-editor,
.html-editor,
.style-editor,
.admin-editor,
.preview {
  .mat-icon {
    margin: 0;
  }

  .mat-icon-button,
  .mat-mdc-icon-button,
  .mat-mdc-icon-button.mat-mdc-button-base {
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 15px;
    border-radius: 50%;
  }

  .mat-datepicker-toggle {
    .mat-mdc-icon-button {
      margin-top: 24px;
      width: 20px;
      height: 20px;
    }
  }

  .mat-mdc-icon-button {
    --mat-mdc-button-persistent-ripple-color: transparent;
  }

  .mat-mdc-form-field {
    font-size: 15px;
    line-height: 1.125;
    font-family: 'EOLNG_default_police1_HelveticaNeue', arial, helvetica, sans-serif;
    letter-spacing: normal;

    ::placeholder {
      font-size: 15px;
    }
  }

  .mat-mdc-text-field-wrapper,
  .mat-mdc-form-field-focus-overlay,
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
    padding: 0;
  }

  .mdc-floating-label,
  .mdc-text-field--disabled.mdc-text-field--filled .mdc-floating-label,
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: $default-brand;
    font-weight: bold;
    transform: translateY(-1.7625em);
    font-size: 13.05px;
  }

  .mdc-text-field--disabled.mdc-text-field--filled {
    background-color: transparent;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 32px;
    padding-bottom: 0;
  }

  .mdc-button {
    letter-spacing: normal;
  }

  .mat-mdc-dialog-container {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
  }

  .mat-mdc-dialog-surface.mdc-dialog__surface {
    padding: 24px;
    overflow: auto;
  }
}

.mat-mdc-slide-toggle.mat-primary,
.style-editor .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #0c4272;
  --mdc-switch-selected-handle-color: #0c4272;
  --mdc-switch-selected-hover-state-layer-color: #0c4272;
  --mdc-switch-selected-pressed-state-layer-color: #0c4272;
  --mdc-switch-selected-focus-handle-color: #0c4272;
  --mdc-switch-selected-hover-handle-color: #0c4272;
  --mdc-switch-selected-pressed-handle-color: #0c4272;
  --mdc-switch-selected-focus-track-color: rgba(12, 66, 114, 0.54);
  --mdc-switch-selected-hover-track-color: rgba(12, 66, 114, 0.54);
  --mdc-switch-selected-pressed-track-color: rgba(12, 66, 114, 0.54);
  --mdc-switch-selected-track-color: rgba(12, 66, 114, 0.54);
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  --mdc-typography-body1-font-size: 14px;
  --mdc-typography-body1-letter-spacing: normal;
}

.table > :not(caption) > * > * {
  padding: 0;
  background-color: none;
  border-bottom-width: 0;
  box-shadow: none;
}

.mat-mdc-icon-button svg,
.mat-mdc-icon-button img {
  --mdc-icon-button-icon-size: 100%;
}

.mat-expansion-panel-content {
  letter-spacing: normal;
}

.mat-mdc-select {
  --mdc-typography-body1-font-size: 15px;
  --mdc-typography-body1-line-height: 1.125em;
  --mdc-typography-body1-letter-spacing: normal;
}
.mat-mdc-select-value {
  height: 24px;
}

.expandable-overlay-panel {
  min-width: fit-content;
}

.mdc-form-field {
  --mdc-typography-body2-letter-spacing: normal;
}

.panel-without-selection-indicator {
  .mdc-list-item--selected {
    span {
      color: #ffffff !important;
    }
    background-color: $default-brand !important;
  }
}
.editor-reminder-toolbar {
  .ck.ck-list {
    overflow-y: auto;
    max-height: 300px;
  }
}

.ck.ck-balloon-panel.ck-powered-by-balloon {
  display: none !important;
}
