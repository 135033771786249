// grey gradient
$eolng-darkgrey: #333333;
$eolng-standart-grey: #979797;
$eolng-grey: #c4c4c4;
$eolng-grey2: #c8c8c8;
$eolng-grey3: #dddddd;
$eolng-light-grey: #eeeeee;
$eolng-lighter-grey: #fafafa;
$eolng-lighter-grey2: #f9f9f9;
$eolng-white: #ffffff;

// other colors
$eolng-very-light-pink: #e0e0e0;
$eolng-light-blue: #00a2ff;
$eolng-blue: #0c4272;
$eolng-light-blue: #d0e9ff;
$eolng-dark-blue-grey: #606368;
$eolng-red: #9b113f;
$eolng-orange: #af7f05;
$eolng-green: #2f8c1d;

// XBRL related colors
$error-fact: #ff0000;
$empty-dd-fact: #ff9e00;
$xbrl-annotation: #ecf2b6;
